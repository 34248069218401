




























import { Component, Vue } from "vue-property-decorator";
import goodsOrderList from "./goodsOrderList.vue";
import resourceOrderList from "./resourceOrderList.vue";
import movieOrderList from "./movieOrder.vue";

@Component({
    components: {
        goodsOrderList,
        resourceOrderList,
        movieOrderList,
    },
})
export default class Order extends Vue {
    // S Data
    activeName: any = "goodsOrderList";

    created() {
        this.activeName = this.$route.query.activeName
            ? this.$route.query.activeName
            : "goodsOrderList";
    }

    // E life cycle
}
