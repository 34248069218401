



































































































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import MaterialSelect from "@/components/material-select/index.vue";
import { apiAfterSaleApply, apiOrderGoodsInfo } from "@/api/order/order";
import { number } from "echarts/core";
@Component({
    components: { MaterialSelect },
})
export default class OrderLogistics extends Vue {
    @Prop({ default: "5vh" }) top!: string | number; //弹窗的距离顶部位置
    @Prop({ default: "0" }) id!: string | number; //订单ID
    /** S Data **/
    visible = false; //是否
    $refs!: { form: any; multipleTable: any };
    orderData: any = {};
    fullscreenLoading = false; //加载方式
    // 发货表单
    form: any = {
        refund_method: "",
        refund_price: "",
        refund_num: "",
        refund_reason: "",
        refund_remark: "",
        order_goods_id: "",
        voucher: [],
    };
    rules = {
        order_goods_id: [
            { required: true, message: "请选择售后商品", trigger: "change" },
        ],
        refund_price: [
            { required: true, validator: this.priceValidator, trigger: "blur" },
        ],
        refund_method: [
            { required: true, message: "请选择售后类型", trigger: "change" },
        ],
        refund_reason: [
            { required: true, message: "请选择售后原因", trigger: "change" },
        ],
    };
    currentRow: any = {};
    reasonList: any = [];
    max_refund_price = 0;
    /** E Data **/
    priceValidator(rule: any, value: any, callback: any) {
        if (value == "" || this.max_refund_price < Number(value)) {
            return callback(new Error("退款金额不得大于实际支付金额"));
        }
        callback();
    }
    /** S Method **/
    // 获取订单详情
    getOrderinfo(refund_method = "") {
        apiOrderGoodsInfo({
            order_goods_id: this.id,
            refund_method: refund_method,
        }).then((res) => {
            this.orderData = res;
            this.orderData.order_goods.map((v) => {
                v.max_refund_num = v.goods_num;
            });
            this.fullscreenLoading = false;
        });
    }

    getReasonList(refund_method = "") {
        this.form.refund_reason = "";
        apiOrderGoodsInfo({
            order_goods_id: this.id,
            refund_method: refund_method,
        }).then((res) => {
            this.reasonList = res.reason;
            this.fullscreenLoading = false;
        });
    }

    // 售后状态检测
    selectableStatus(row: any, rowIndex: number) {
        // return true;
        return (
            row.after_sale_btn &&
            Boolean(row.after_sale_btn === 1 || row.after_sale_btn === 4)
        );
    }

    changeRefundNum(val: number, row: any) {
        this.form.refund_num = val;
    }

    handleSelectionChange(val: any) {
        const refs = this.$refs["multipleTable"] as HTMLFormElement;
        if (val.length > 1) {
            refs.clearSelection();
            refs.toggleRowSelection(val.pop());
        }
    }

    selectTable(selection: any, row: any) {
        this.currentRow = row;
        const price = Number(this.currentRow.total_pay_price);
        this.form.refund_price = price;
        this.max_refund_price = price;
        this.form.order_goods_id = this.currentRow.order_goods_id;
        this.form.refund_num = this.currentRow.goods_num;
    }

    handleCurrentChange(row: any) {
        // const refs = this.$refs["multipleTable"] as HTMLFormElement;
        // refs.clearSelection();
        // refs.toggleRowSelection(row);
        // this.currentRow = row;
    }

    // 点击取消
    handleEvent(type: "cancel" | "confirm") {
        if (type === "cancel") {
            this.close();
        }
        if (type === "confirm") {
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    // return;
                    this.fullscreenLoading = true;
                    apiAfterSaleApply(this.form)
                        .then((res) => {
                            this.fullscreenLoading = false;
                            this.close();
                        })
                        .catch((err) => {
                            this.fullscreenLoading = false;
                        });
                } else {
                    console.log("error submit!!");
                    return false;
                }
            });
        }
    }

    // 打开弹窗
    async onTrigger() {
        this.fullscreenLoading = true;
        this.getOrderinfo();
        this.visible = true;
    }

    // 关闭弹窗
    close() {
        this.form = {
            refund_method: "",
            refund_reason: "",
            refund_remark: "",
            refund_price: "",
            refund_num: 1,
            order_goods_id: null,
            voucher: [],
        };
        this.reasonList = [];
        this.visible = false;
    }

    //判断多选框是否可选
    selectableDisable(item: any) {
        return item.delivery_goods_sum < item.goods_num;
    }
}
