

























































































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import { apiOrderPrint } from "@/api/application/print";
import LsDialog from "@/components/ls-dialog.vue";
import LsPagination from "@/components/ls-pagination.vue";
import afterForm from "@/components/order/afterForm.vue";
import OrderLogistics from "@/components/order/order-logistics.vue";
import {
    apiOrderRemarks,
    apiOrderCancel,
    apiOrderConfirm,
} from "@/api/order/order";
import { apiSelffetchVerification } from "@/api/application/selffetch";

@Component({
    components: {
        LsDialog,
        LsPagination,
        OrderLogistics,
        afterForm,
    },
})
export default class OrderPane extends Vue {
    @Prop() value: any;
    @Prop() pager!: any;
    lists: any = [];
    // 去订单详情
    toOrder(id: any) {
        this.$router.push({
            path: "/trade/order_detail",
            query: { id, backQuery: "goodsOrderList" },
        });
    }

    // 取消订单
    orderCancel(id: Number) {
        apiOrderCancel({ id: id }).then((res) => {
            // this.getOrderLists();
            this.$emit("refresh");
        });
    }
    //售后
    toAfterSale() {}

    get tableData(): any {
        this.pager.lists.map((row) => {
            let platform_order_goods = row.platform_order_goods;
            let supplier_row = 1;
            platform_order_goods.map((goods, index) => {
                if (
                    index > 0 &&
                    goods.supplier_id ===
                        platform_order_goods[index - 1].supplier_id
                ) {
                    supplier_row += 1;
                }
                goods.supplier_row = supplier_row;
            });
            row.platform_order_goods = platform_order_goods.reverse();
        });

        return this.pager.lists;
    }
}
